@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .scrollbar-show::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .scrollbar-show::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .scrollbar-show::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: gray;
  }
  .scrollbar-show::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}
