@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

/* Calendar 전체 border 제거 및 Pretendard-Regular 폰트 적용 */
.react-calendar {
  width: 100%;
  border: none;
  font-family: 'Pretendard-Regular', Arial, sans-serif; /* 폰트 적용 */
}

/* 연도와 월을 나타내는 부분의 폰트 bold 처리 */
.react-calendar__navigation__label {
  font-weight: bold;
  font-size: 18px; /* 크기도 조정할 수 있습니다 */
}

/* 날짜 타일의 디자인 */
.react-calendar__tile {
  padding: 5px;
  font-size: 16px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  font-family: 'Pretendard-Regular', Arial, sans-serif;
  width: auto;
  max-width: 100%;
  height: auto;
  transition: background-color 0.3s ease;
}
/* 요일 헤더의 색상을 변경 및 폰트 적용 */
.react-calendar__month-view__weekdays__weekday {
  color: rgb(197, 197, 199);
  font-weight: bold;
  font-size: 14px; /* 요일의 폰트 크기도 약간 줄임 */
  text-align: center;
  font-family: 'Pretendard-Regular', Arial, sans-serif; /* 폰트 적용 */
}

/* 선택된 날짜에 빨간색 배경 */
.react-calendar__tile--active {
  background-color: #e53700 !important; /* 선택된 날짜는 빨간색 배경 */
  color: white;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard-Regular', Arial, sans-serif; /* 폰트 적용 */
}

/* 오늘 날짜에 "오늘" 텍스트 추가 */
.react-calendar__tile--now {
  background-color: white !important; /* 기본적으로 오늘 날짜는 배경색 없음 */
  color: black;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Pretendard-Regular', Arial, sans-serif; /* 폰트 적용 */
}

/* 오늘 날짜 하단에 '오늘' 텍스트 추가 */
.react-calendar__tile--now::after {
  content: '오늘';
  font-size: 10px;
  color: black;
  position: absolute;
  bottom: 5px;
  font-family: 'Pretendard-Regular', Arial, sans-serif; /* 폰트 적용 */
}

/* 오늘 날짜가 선택된 경우 빨간색으로 스타일 적용 */
.react-calendar__tile--now.react-calendar__tile--active {
  background-color: #e53700 !important; /* 오늘 날짜가 선택된 경우에도 빨간색 */
  color: white !important;
}

/* 오늘 날짜가 선택된 경우 "오늘" 텍스트도 흰색으로 변경 */
.react-calendar__tile--now.react-calendar__tile--active::after {
  color: white !important;
}
